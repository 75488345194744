import React from "react";
import {
    EditMeetForm,
    MeetList,
    NewMeetForm,
} from "../../../components/components";
import { useSelector } from "react-redux";
import MenuLayout from "../../../layouts/menu-layout";

const MeetPage = () => {
    const { currentOperation } = useSelector((state) => state.misc);

    return (
        <MenuLayout>
            {currentOperation === "newMeeting" && (
                <>
                    <NewMeetForm />
                </>
            )}
            {currentOperation === "editMeeting" && (
                <>
                    <EditMeetForm />
                </>
            )}
            <MeetList />
        </MenuLayout>
    );
};

export default MeetPage;
