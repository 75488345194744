import React from "react";
import { Contact, PageHeader, Spacer } from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const ContactPage = () => {
    return (
        <MenuLayout>
            <Contact />
        </MenuLayout>
    );
};

export default ContactPage;