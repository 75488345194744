import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeet, getMeetsByPage } from "../../../../api/api";
import { functions } from "../../../../helpers/helpers";
import {
  setCurrentOperation,
  setCurrentRecord,
  setListRefreshToken,
} from "../../../../context/slices/misc-slices";
import { Button, Card, Container } from "react-bootstrap";
import { FiEdit, FiTrash } from "react-icons/fi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const MeetList = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
  });
  const { listRefreshToken } = useSelector((state) => state.misc);
  const dispatch = useDispatch();

  const loadData = async (page) => {
    setLoading(true);
    try {
      const response = await getMeetsByPage(page, lazyState.rows);
      setList(response.data.content);
      setTotalRows(response.data.totalElements);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    functions
      .swalQuestion("Are you sure you want to delete this meeting?")
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          try {
            const response = await deleteMeet(id);
            dispatch(setListRefreshToken(Math.random()));
            dispatch(setCurrentOperation(null));
            functions.swalToast(`${response.data.message}`, "success");
          } catch (error) {
            functions.swalToast(
              "There was an error deleting this meeting",
              "error"
            );
          } finally {
            setLoading(false);
          }
        }
      });
  };

  const onPage = (event) => setLazyState(event);

  const handleEdit = (meeting) => {
    dispatch(setCurrentOperation("editMeeting"));
    dispatch(setCurrentRecord(meeting));
  };

  const operationTemplate = (row) => {
    if (row.built_in) return null;
    return (
      <div className="d-flex">
        <Button className="btn-link" onClick={() => handleEdit(row)}>
          <FiEdit />
        </Button>
        <Button className="btn-link" onClick={() => handleDelete(row.userId)}>
          <FiTrash />
        </Button>
      </div>
    );
  };

  const startTimeTemplate = (row) => functions.formatTime(row.startTime);
  const stopTimeTemplate = (row) => functions.formatTime(row.stopTime);

  useEffect(() => {
    loadData(lazyState.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyState, listRefreshToken]);

  return (
    <Container className="dean-list-container">
      <Card>
        <Card.Body>
          <Card.Title className="d-flex justify-content-between align-items-center">
            Meetings List
            <Button onClick={() => dispatch(setCurrentOperation("newMeeting"))}>
              New Meeting
            </Button>
          </Card.Title>
          <DataTable
            value={list}
            lazy
            dataKey="id"
            paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRows}
            onPage={onPage}
            loading={loading}
            tableStyle={{ minWidth: "50rem", fontSize: "0.9rem" }}
            stripedRows
          >
            <Column header="Date" field="date"></Column>
            <Column header="Start Time" body={startTimeTemplate}></Column>
            <Column header="Stop Time" body={stopTimeTemplate}></Column>
            <Column header="Description" field="description"></Column>
            <Column
              headerStyle={{ width: "2rem" }}
              body={operationTemplate}
            ></Column>
          </DataTable>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MeetList;
