import React from "react";
import { Navigation } from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const DashboardPage = () => {
    return (
        <MenuLayout>
            <Navigation />
        </MenuLayout>
    );
};

export default DashboardPage;