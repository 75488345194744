import React from "react";
import "./page-header.scss";
import { useLocation } from "react-router-dom";

const PageHeader = () => {
    const location = useLocation();

    return (
        <div className="page-header-container">
            <span>{location.pathname.split("/").slice(-1).pop().split("-").join(" ")}</span>                       
        </div>        
    );
};

export default PageHeader;