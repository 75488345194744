import React from "react";
import {
    EditStudentInfoForm,
    NewStudentInfoForm,
    StudentInfoList,
} from "../../../components/components";
import { useSelector } from "react-redux";
import MenuLayout from "../../../layouts/menu-layout";

const StudentInfoPage = () => {
    const { currentOperation } = useSelector((state) => state.misc);

    const content = () => {
        switch (currentOperation) {
            case "newStudentInfo":
                return (
                    <>
                        <NewStudentInfoForm />
                    </>
                );
            case "editStudentInfo":
                return (
                    <>
                        <EditStudentInfoForm />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <MenuLayout>
            {content()}
            <StudentInfoList />
        </MenuLayout>
    );
};

export default StudentInfoPage;
