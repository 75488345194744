import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    EditViceDeanForm,
    NewViceDeanForm,
    ViceDeanList,
} from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const ViceDeanPage = () => {
    const { currentOperation } = useSelector((state) => state.misc);

    const content = () => {
        switch (currentOperation) {
            case "newViceDean":
                return (
                    <>
                        <NewViceDeanForm />
                    </>
                );
            case "editViceDean":
                return (
                    <>
                        <EditViceDeanForm />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <MenuLayout>
            {content()}
            <ViceDeanList />
        </MenuLayout>
    );
};

export default ViceDeanPage;
