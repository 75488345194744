import React from "react";
import MenuLayout from "../../../layouts/menu-layout";
import {
  LessonProgramListSelected,
  LessonProgramListUnselected,
} from "../../../components/components";

const ChooseLessonPage = () => {
  return (
    <MenuLayout>
      <LessonProgramListUnselected />
      <LessonProgramListSelected />
    </MenuLayout>
  );
};

export default ChooseLessonPage;
