import React from "react";
import {
    FeaturedCourses,
    MobileApp,
    Slider,
    UpcomingEvents,
    Welcome,
} from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const HomePage = () => {
    return (
        <MenuLayout >
            <Slider />
            <Welcome />
            <FeaturedCourses />
            <UpcomingEvents />
            <MobileApp />
        </MenuLayout>
    );
};

export default HomePage;
