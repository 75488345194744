import React from "react";
import { LoginForm, PageHeader, Spacer } from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const LoginPage = () => {
    return (
        <MenuLayout>
            <LoginForm />
        </MenuLayout>
    );
};

export default LoginPage;
