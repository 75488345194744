import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import "./bread-crumbs.scss"

const BreadCrumbs = () => {
  const {pathname}= useLocation();
  let currentLink="";
  let currentLinkTitle="";

  const crumbs= pathname.split("/")
  .filter( crumb  => crumb !== "")
  .map(crumb => {
    currentLink += `/${crumb}`
    currentLinkTitle = crumb.charAt(0).toUpperCase()+ crumb.slice(1).replace(/-(\w)/g, function(p1) {
      return " "+p1.charAt(1).toUpperCase();
    });

    return (
      <div className='crumb' key={crumb}>
        <Link to={currentLink}>{currentLinkTitle}</Link>
      </div>
    )
  })

  return (
    <div className='bread-crumbs'>{crumbs}</div>
  )
}

export default BreadCrumbs