import React from "react";
import { useSelector } from "react-redux";
import {
    EditTeacherForm,
    NewTeacherForm,
    TeacherList,
} from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const TeacherPage = () => {
    const { currentOperation } = useSelector((state) => state.misc);

    const content = () => {
        switch (currentOperation) {
            case "newTeacher":
                return (
                    <>
                        <NewTeacherForm />
                    </>
                );
            case "editTeacher":
                return (
                    <>
                        <EditTeacherForm />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <MenuLayout>
            {content()}
            <TeacherList />
        </MenuLayout>
    );
};

export default TeacherPage;
