import React from "react";
import { Courses, PageHeader, Spacer } from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const CoursesPage = () => {
    return (
        <MenuLayout>
            <Courses />
        </MenuLayout>
    );
};

export default CoursesPage;
