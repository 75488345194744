import { EncryptStorage } from "encrypt-storage";

export const encryptLocalStorage = new EncryptStorage(
    process.env.REACT_APP_STORAGE_ENCRYPTION_KEY
);

export const encryptSessionStorage = new EncryptStorage(
    process.env.REACT_APP_STORAGE_ENCRYPTION_KEY,
    {
        storageType: "sessionStorage",
    }
);
