import React from "react";
import {
    Instructors,
    Welcome,
} from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const AboutPage = () => {
    return (
        <MenuLayout>
            <Welcome />
            <Instructors />
        </MenuLayout>
    );
};

export default AboutPage;