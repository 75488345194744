import React from "react";
import {
    GradesList,
    GradesMeetsList,
} from "../../../components/components";
import MenuLayout from "../../../layouts/menu-layout";

const GradesMeetsPage = () => {
    return (
        <MenuLayout>
            <GradesList />
            <GradesMeetsList />
        </MenuLayout>
    );
};

export default GradesMeetsPage;