import * as Yup from "yup";

const validationFields = {
  birthDay: Yup.date().required("Enter your birth date"),
  birthPlace: Yup.string().required("Enter the place of your birth"),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  gender: Yup.string()
    .required("Please select your gender")
    .oneOf(["MALE", "FEMALE", "NOTSPECIFIED"], "You must select a gender"),
  name: Yup.string()
    .min(2, "Name must be at least 2 characters long")
    .max(16, "Name must be at most 16 characters long")
    .required("Enter your first name"),
  password: Yup.string()
    .required("Enter your password")
    .min(8, "Password must be at least 8 characters long")
    .max(60, "Password must be at most 60 characters long")
    .matches(/[a-z]+/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]+/, "Password must contain at least one uppercase letter")
    .matches(/\d+/, "Password must contain at least one number"),
  phoneNumber: Yup.string()
    .required("Enter your phone number")
    .matches(
      /\d{3}-\d{3}-\d{4}/g,
      "Phone number must be in format XXX-XXX-XXXX"
    ),
  ssn: Yup.string()
    .required("Enter your SSN")
    .matches(/\d{3}-\d{2}-\d{4}/g, "SSN must be in format XXX-XX-XXXX"),
  surname: Yup.string()
    .min(2, "Surname must be at least 2 characters long")
    .max(16, "Surname must be at most 16 characters long")
    .required("Enter your last name"),
  username: Yup.string()
    .min(4, "At least 4 char")
    .max(25, "At least 25 char")
    .required("Enter your username"),
  email: Yup.string()
    .email("Enter a valid email")
    .min(5, "Email must be at least 5 characters long")
    .max(50, "Email must be at most 50 characters long")
    .required("Enter is mandatory")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must contain charachters, '@', '.' "
    ),
  motherName: Yup.string()
    .min(2, "Mother name must be at least 2 characters long")
    .max(16, "Mother name must be at most 16 characters long")
    .required("Enter your mother name"),
  fatherName: Yup.string()
    .min(2, "Father name must be at least 2 characters long")
    .max(16, "Father name must be at most 16 characters long")
    .required("Enter your father name"),
};

export const newDeanFormValidationSchema = Yup.object({
  birthDay: validationFields.birthDay,
  birthPlace: validationFields.birthPlace,
  confirmPassword: validationFields.confirmPassword,
  gender: validationFields.gender,
  name: validationFields.name,
  surname: validationFields.surname,
  password: validationFields.password,
  phoneNumber: validationFields.phoneNumber,
  ssn: validationFields.ssn,
  username: validationFields.username,
});

  export const newEducationTermFormValidationSchema = Yup.object({
    term: Yup.string()
        .required("Enter the term")
        .oneOf(["SPRING_SEMESTER", "FALL_SEMESTER"], "Select a term"),
    startDate: Yup.date().required("Select the start date"),
    endDate: Yup.date().required("Select the end date"),
    lastRegistrationDate: Yup.date().required("Select the last registration date"),
});

export const newLessonFormValidationSchema = Yup.object({
  lessonName: Yup.string()
    .required("Enter the lesson name")
    .min(2, "At least 2 characters")
    .max(25, "At most 25 characters"),
  creditScore: Yup.number()
    .required("Enter the credit score")
});

export const newLessonProgramFormValidationSchema = Yup.object({
  lessonIdList: Yup.string().required("Select the lesson"),
  day: Yup.string().required("Select the day"),
  startTime: Yup.string().required("Select the start time"),
  stopTime: Yup.string().required("Select the stop time"),
  educationTermId: Yup.string().required("Select the education term"),
});

export const newMeetFormValidationSchema = Yup.object({
  studentIds: Yup.array().required("Select at least a student"),
  date: Yup.date().required("Select a date"),
  description: Yup.string()
      .required("Enter a description")
      .min(2, "At least 2 characters")
      .max(250, "Max 250 characters"),
  startTime: Yup.string().required("Select the time"),
  stopTime: Yup.string().required("Select the time"),
});

export const studentInfoFormValidationSchema = Yup.object({
  lessonId: Yup.string().required("Select lesson"),
  studentId: Yup.string().required("Select student"),
  educationTermId: Yup.string().required("Select education term"),
  absentee: Yup.number().required("Enter absentee count in days"),
  midtermExam: Yup.number().required("Enter midterm exam result"),
  finalExam: Yup.number()
    .min(0, "Min 0.0")
    .max(100, "Max 100.0")
    .required("Enter final exam result"),
  infoNote: Yup.string()
    .min(10, "At least 10 char")
    .max(200, "At most 200 char")
    .required("Enter info note"),
});

export const newStudentFormValidationSchema = Yup.object({
  birthDay: validationFields.birthDay,
  birthPlace: validationFields.birthPlace,
  confirmPassword: validationFields.confirmPassword,
  gender: validationFields.gender,
  name: validationFields.name,
  surname: validationFields.surname,
  password: validationFields.password,
  phoneNumber: validationFields.phoneNumber,
  ssn: validationFields.ssn,
  username: validationFields.username,
  email: validationFields.email,
  motherName: validationFields.motherName,
  fatherName: validationFields.fatherName,
  advisorTeacherId: Yup.string().required("Select a advisor teacher"),
});

export const teacherFormValidationSchema = Yup.object({
  lessonsIdList: Yup.array().required("Select at least a lesson"),
  birthDay: validationFields.birthDay,
  birthPlace: validationFields.birthPlace,
  confirmPassword: validationFields.confirmPassword,
  gender: validationFields.gender,
  name: validationFields.name,
  surname: validationFields.surname,
  password: validationFields.password,
  phoneNumber: validationFields.phoneNumber,
  ssn: validationFields.ssn,
  username: validationFields.username,
  email: validationFields.email,
  isAdvisorTeacher: Yup.boolean().required()
});
