import React from "react";
import { PageHeader, Spacer } from "../components/components";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../components/common/bread-crumbs/bread-crumbs";

const MenuLayout = ({ children }) => {
  const location = useLocation();
  const childrenArray = React.Children.toArray(children);

  const componentToRender = () => {
    return (
      <>
        {location.pathname === "/" || (
          <>
            <PageHeader /> 
            <BreadCrumbs />
          </>
        )}

        {childrenArray.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            {item && <Spacer />}
          </React.Fragment>
        ))}
      </>
    );
  };

  return <>{componentToRender()}</>;
};

export default MenuLayout;
