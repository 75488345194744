import React, { useEffect } from "react";
import MenuLayout from "../../../layouts/menu-layout";
import {
  DeanList,
  EditDeanForm,
  NewDeanForm,
} from "../../../components/components";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentOperation } from "../../../context/slices/misc-slices";

const DeanPage = () => {
  const { currentOperation } = useSelector((state) => state.misc);
  const dispatch= useDispatch();

  const content = () => {
    switch (currentOperation) {
      case "newDean":
        return <NewDeanForm />;
      case "editDean":
        return <EditDeanForm />;
      default:
        return;
    }
  };

  useEffect(()=>{
    
    return ()=>{
      dispatch(setCurrentOperation(null));  
  }
  },[])

  return (
    <MenuLayout>
        {content()}
      <DeanList />
    </MenuLayout>
  );
};

export default DeanPage;
