import React from "react";
import RootLayout from "./root-layout";
import { Outlet } from "react-router-dom";
import { Menubar, Topbar, Footer } from "../components/components";

const UserLayout = () => {
    return (
        <RootLayout>
            <Topbar />
            <Menubar />
            <Outlet />
            <Footer />
        </RootLayout>
    );
};

export default UserLayout;