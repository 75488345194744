import React from "react";
import {
    EditStudentForm,
    NewStudentForm,
    StudentList,
} from "../../../components/components";
import { useSelector } from "react-redux";
import MenuLayout from "../../../layouts/menu-layout";

const StudentPage = () => {
    const { currentOperation } = useSelector((state) => state.misc);

    const content = () => {
        switch (currentOperation) {
            case "newStudent":
                return (
                    <>
                        <NewStudentForm />
                    </>
                );
            case "editStudent":
                return (
                    <>
                        <EditStudentForm />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <MenuLayout>
            {content()}
            <StudentList />
        </MenuLayout>
    );
};

export default StudentPage;
