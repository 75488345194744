import React from "react";
import MenuLayout from "../../../layouts/menu-layout";
import { ContactMessageList } from "../../../components/components";

const ContactMessagesPage = () => {
  return (
    <MenuLayout>
      <ContactMessageList />
    </MenuLayout>
  );
};

export default ContactMessagesPage;
